import React, { useEffect } from "react"
import { graphql, Link } from "gatsby"
import { motion } from "framer-motion"
import { PortableText } from "@portabletext/react"
import ReactPlayer from "react-player"
import Seo from "../components/seo"

//components
import Layout from "../components/layout"
import Cross from "../components/cross"
import PageIntroText from "../components/pageIntroText"
import ArticleImageCarousel from "../components/articleImageCarousel"

//utils
import { variants } from "../utils/framerUtils"

//Portable text components
const introTextComponents = {
  block: {
    normal: ({ children }) => <PageIntroText>{children}</PageIntroText>,
  },
}

const articleComponents = {
  block: {
    normal: ({ children }) => (
      <p className="col-span-3 col-start-6 mt-lead-1 md:mt-0">{children}</p>
    ),

    h3: ({ children }) => (
      <h3 className="col-span-3 col-start-2 text-love-grey mb-lead-1">
        {children}
      </h3>
    ),
    blockquote: ({ children }) => (
      <p className="col-span-4 col-start-1 font-light text-mobile-medium md:text-desktop-medium text-love-grey leading-medium my-lead-2 md:my-0 px-lead-1">
        {children}
      </p>
    ),
  },
  types: {
    vimeo: ({ value }) => (
      <div className="col-span-7 col-start-2 my-lead-4">
        <div className="relative pt-[56.33803%]">
          <ReactPlayer
            className="absolute top-0 left-0 bg-black"
            url={value.url}
            controls={true}
            width={"100%"}
            height={"100%"}
          />
        </div>
        <p className="text-love-grey mt-[0.75rem]">{value.videoCaption}</p>
      </div>
    ),
    articleImageCarousel: ({ value }) => (
      <ArticleImageCarousel rawImages={value.carouselImages} />
    ),
  },
}

const LovefartPost = ({ data, pageContext }) => {
  //Effect to fix bug that was making page scroll to position of previous page
  useEffect(() => {
    if (typeof window !== "undefined") {
      window.scrollTo({ top: 0 })
    }
  }, [])
  return (
    <Layout footer>
      {/* sidebar */}
      <div className="fixed p-lead-1 md:p-0 top-0 left-0 w-full md:top-lead-1 md:left-lead-1 md:w-[calc((100%/12*3)-1.4rem)] md:h-[calc(100vh-2.8rem)] z-10 bg-white md:bg-transparent">
        <h1 className="font-light text-mobile-medium md:text-desktop-medium leading-medium md:h-lead-15">
          {data.sanityNewsItem.title}
        </h1>
        <motion.div
          variants={variants.contentChild}
          className="fixed right-lead-1 top-lead-1"
        >
          <Link to="/lovefart">
            <Cross />
          </Link>
        </motion.div>
        {/* sidebar info desktop*/}
        <div className="hidden mt-lead-1 md:block">
          <PortableText
            value={data.sanityNewsItem.article._rawSidebarInfo}
            components={articleComponents}
          />
        </div>
      </div>
      {/* Main Content */}
      <div className="md:col-start-5 md:col-span-7">
        <PortableText
          value={data.sanityNewsItem.article._rawArticleIntro}
          components={introTextComponents}
        />
      </div>
      <div className=" md:col-start-4 md:col-span-8 md:grid md:grid-cols-8 md:gap-lead-1">
        {/* sidebar info mobile */}
        <div className="block md:col-start-2 md:col-span-3 md:hidden mb-lead-4">
          <PortableText
            value={data.sanityNewsItem.article._rawSidebarInfo}
            components={articleComponents}
          />
        </div>
        <PortableText
          value={data.sanityNewsItem.article._rawArticle}
          components={articleComponents}
        />
      </div>
    </Layout>
  )
}

export default LovefartPost
export const Head = ({ data }) => <Seo title={data.sanityNewsItem.title} />
export const query = graphql`
  query ($pageId: String) {
    sanityNewsItem(id: { eq: $pageId }) {
      title
      article {
        _rawArticleIntro
        _rawArticle
        _rawSidebarInfo
        includeSidebarInfo
      }
    }
  }
`
